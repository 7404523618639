<template> 
<div>
            <v-row dense class="caption px-2 item font-weight-black"   >
              <v-col class="text-center">
              Tipo de Cabaña: {{tipoHabitacion}} {{tipoHabitacion2}}
              </v-col> 
            </v-row>
            <v-row dense >
               <v-col cols="7">
                            <v-img contain aspect-ratio="1" :src="require('../assets/rda/'+ this.plano + '.png')" ></v-img>
              </v-col>
               <v-col cols="5">
                    <v-row dense align="center" class="pa-0 ma-0 my-4 py-2" style="background-color:#F2F2F2">
                      <v-col cols="5">
                        <v-img contain :src=path_sup ></v-img>
                      </v-col>
                      <v-col cols="7" class="caption">
                        Superficie {{superficie}} m2
                      </v-col>
                    </v-row>
                     <v-row dense align="center" class="pa-0 ma-0 my-4 py-2" style="background-color:#F2F2F2">
                      <v-col cols="5">
                        <v-img  contain :src=path_maximo ></v-img>
                      </v-col>
                      <v-col cols="7" class="caption" >
                         Máximo {{pax}} Personas
                      </v-col>
                    </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col >
                 <v-list dense subheader v-for="(item, j) in ambientesObtenidos"
                    :key="`${j}-${item.det_id_amb}`" :id="item.det_id_amb">
                    <v-subheader 
                      class="text-uppercase body-2 font-weight-black font-italic item" >
                      <v-icon dense :color=colorMenu class="ma-0 pa-0">mdi-menu-right</v-icon>
                      {{ item.det_descripcion }}   
                    </v-subheader> 
                        <v-list-item
                            dense
                            v-for="(subitem, k) in filtroPorTipo(item.det_id_amb)"
                            :key="`${k}-${subitem.det_id_amb}`"
                            class="itempar alto_item" style="font-size:11px !important"
                          >    
                            <v-list-item-content>
                                      <v-list-item-title
                                        class="caption" style="font-size:11px !important" >
                                            {{subitem.inv_cantidad}} {{subitem.art_descripcion}}
                                      </v-list-item-title>
                            </v-list-item-content>                      
                    </v-list-item>
              </v-list>
              </v-col>            
            </v-row>              
          </div>                  
</template>

<script>
export default {
  name: 'listarItemsInventario',
  props: {
        tipoHabitacion: String,
        tipoHabitacion2: String,
        pax: Number,
        superficie: Number,
        plano: String,
        inventarioObtenido: Array,
        ambientesObtenidos: Array,
        itemTabs: Array,
        colorMenu: String,
        moneda: String,
        piegeneral: String
  },
  data() {
           return {          
               path_plano: require('../assets/rda/'+ this.plano + '.png'),
               path_sup: require('../assets/rda/superficie2.png'),
               path_maximo: require('../assets/rda/personas2.png')                                 
           }
  },
  methods: {
    filtroPorTipo(id) {
                                    let resultado = [];
                                // alert(id)
                                 // alert(JSON.stringify(this.inventarioObtenido))
                                    resultado = this.inventarioObtenido.filter(t => t.cab_id_ambiente === id)
                                    return resultado;
                                  }
        
  },
  created: async function() {
}
  
}
</script>
<style scoped>
.itempar:nth-child(odd) {
  background-color: #f7f7f7;
}

.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 0;
}

.alto_item {
      min-height: 20px;
      height: 20px;   
}

.item {
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  border-color: green;
  border-width: 1px;
  height: auto;
  min-height:15px;
  color: green !important;
}
</style>
