<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar :color=colorMenu flat app dense height="40px" dark>
      <v-btn icon :to="{ name: complejo }">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{ tituloHeader }}</v-toolbar-title>
    
    </v-app-bar>
          <v-row dense>
                  <v-col cols="12" class="caption ma-0 px-2">
                         Seleccione el tipo de cabaña que desea ver
                  </v-col>         
                 <!-- <v-col cols="3" xd="2" md="1" class="mx-2 pa-0 px-0">
                          <v-text-field name="numero" dense solo class="ma-0 pa-0" autofocus append-icon="mdi-magnify" single-line>
                          </v-text-field>                         
                  </v-col>-->
           </v-row>
           <v-row dense class="ma-0 pa-0">
                   <v-col cols=12 md=6>
                          <v-btn-toggle active-class="black">
                                    <v-row dense>                            
                                          <v-col cols="4" xd="2" v-for="(itema, a) in tiposObtenidos" :key="`${a}-${itema.id}`" >
                                            <v-btn   :id=itema.id dense block x-small dark   class="ma-0 mt-0 pa-4 px-1" text-color="white" :color=colorMenu @click="filtroPorTipo(itema.id)">
                                              {{itema.descripcion}} <br> {{itema.descripcion2}}                                             
                                            </v-btn>
                                          </v-col>                           
                                    </v-row>
                           </v-btn-toggle>
                   </v-col>                        
           </v-row>
           <v-row dense>
            <v-col  v-if=(mostrarHabitacion) cols="12" md="6">
              <listarItemsInventario :plano=plano :superficie=superficie :pax=pax :tipoHabitacion=tipoHabitacion :tipoHabitacion2=tipoHabitacion2  :ambientesObtenidos=ambientesObtenidosFiltrados :inventarioObtenido=inventarioObtenidoFiltrado> </listarItemsInventario>
          
            </v-col>

           </v-row>
          
  </v-container>
</template>

<script>

import listarItemsInventario from '../components/listarItemsInventario'
import axios from 'axios'
export default {
  components: { listarItemsInventario},
  name: 'inventariorda',
  props: {
            tituloHeader: String,
            idcomplejo: Number,
            complejo: String,
            menu: Number,
            colorMenu: String,
            moneda: String,
            piegeneral: String,
  },
  data() {
    return {
      mostrarHabitacion:false,
      idtipo:1,   
      mostrarTab: "false",
      activeBtn: 1,
      tabs: null,
      showNav: true,
      inventarioObtenido: [],
     
      inventarioObtenidoFiltrado: [],
      tiposObtenidos: [],
      ambientesObtenidos:[],
      ambientesObtenidosFiltrados:[],
      tipoHabitacion: "",
      tipoHabitacion2:"",
      superficie:"",
      pax: "",
      plano:"",
      itemTabs:[],
      buscar: "",     
    };
  },
  created: async function() {
   //      await axios.get(process.env.VUE_APP_API +'/complejo/menu_tabs',
    //         {params:{
     //              idcomplejo: this.idcomplejo,
      //             menu: this.menu
       //             }
  //      })
   //   .then((response) => {
  //                        this.itemTabs= response.data
   //                   
    //  })
         await axios.get(process.env.VUE_APP_API +'/inventario/tipos_cabanas'
             )
             .then((response) => {
                                this.tiposObtenidos= response.data.data
                                this.itemTabs=this.tiposObtenidos
                           //    alert(JSON.stringify(this.tiposObtenidos))
                            // this.categoriasObtenidasFiltrada=this.categoriasObtenidas
      })
         await axios.get(process.env.VUE_APP_API +'/inventario/inventario',
             {params:{
                   idcomplejo: this.idcomplejo,
                   idtipo: this.idtipo
                    }
        })
      .then((response) => {
                          this.inventarioObtenido= response.data.data
                       //  alert(JSON.stringify(this.inventarioObtenido))
                         
      })
      await axios.get(process.env.VUE_APP_API +'/inventario/inventario_ambientes',
              )
             .then((response) => {
                                this.ambientesObtenidos= response.data.data
                              
                            //  alert(JSON.stringify(this.ambientesObtenidos))
                            // this.categoriasObtenidasFiltrada=this.categoriasObtenidas
      })
  //     this.cambio(0)   
  },
  
  methods: {
      
 //   goto(id) {
//      let destino = "#" + id;
 //     this.$vuetify.goTo(destino);
  //  },
  //  cambio(item) {
 //     this.filtroPorTipo(this.itemTabs[item].idpadre);
  //  },
 //   busca() {
   //   this.buscar = "";
 //     this.mostrarTab = !this.mostrarTab;
  //  },
   // filtroPorTab(id) {
    //  this.filtroPorTipo(id);
   // },
  //filtroPorCarta(id) {
   //   this.cartaObtenidaFiltrada = this.cartaObtenida.filter(
     //   categoria => categoria.idcategoria === id
     // );
    //},
  filtroPorTipo(id) {
    //alert(id)
    // console.log(JSON.stringify(this.inventarioObtenido))
         this.inventarioObtenidoFiltrado=this.inventarioObtenido.filter(tip => tip.cab_id_tipo_cabana===id)
         this.ambientesObtenidosFiltrados=this.ambientesObtenidos.filter(tip_c => tip_c.amb_id_tipo_cabana===id)

        //alert(JSON.stringify(this.am))
       // alert(id)
     //   alert(JSON.stringify(this.ambientesObtenidosFiltrados))
          this.tiposObtenidosFiltrados= this.tiposObtenidos.filter(tiposfiltrados => tiposfiltrados.id===id)
       //   alert(JSON.stringify(this.tiposObtenidosFiltrados))
          //alert(JSON.stringify(this.tiposObtenidosFiltrados))
          this.id_tipoHabitacion= this.tiposObtenidosFiltrados[0].id
          this.tipoHabitacion=this.tiposObtenidosFiltrados[0].descripcion
           this.tipoHabitacion2=this.tiposObtenidosFiltrados[0].descripcion2
           this.pax=this.tiposObtenidosFiltrados[0].pax         
           
           this.plano=this.tiposObtenidosFiltrados[0].planoimg
          this.superficie= this.tiposObtenidosFiltrados[0].superficie
          this.mostrarHabitacion=true
          
          
       // alert(this.plano)

    },
    
  }
};
</script>
